var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"devices-list-wrapper"},[_c('b-card',{attrs:{"no-body":""}},[_c('b-tabs',{attrs:{"card":""},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('b-tab',{attrs:{"title":_vm.$t('label.desktop'),"active":""}},[_c('b-table',{attrs:{"items":_vm.desktopList,"fields":_vm.fields,"show-empty":""},scopedSlots:_vm._u([{key:"cell(device_uuid)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.maskUuid(item.device_uuid))+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [(!_vm.isActiveDevice(item.device_uuid))?_c('b-button',{attrs:{"variant":"light-danger"},on:{"click":function($event){return _vm.confirmDelete(item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('btn.delete'))+" ")]):_c('span',{staticClass:"d-inline-block",attrs:{"id":"desktop-delete-btn","tabindex":"0"}},[_c('b-button',{staticStyle:{"pointer-events":"none"},attrs:{"variant":"light-danger","disabled":""}},[_vm._v(" "+_vm._s(_vm.$t('btn.delete'))+" ")])],1),_c('b-tooltip',{attrs:{"target":"desktop-delete-btn"}},[_vm._v(_vm._s(_vm.$t('help.cant_delete_current_session')))])]}}])})],1),_c('b-tab',{attrs:{"title":_vm.$t('label.smartphone')}},[_c('b-table',{attrs:{"items":_vm.mobileList,"fields":_vm.fields,"show-empty":""},scopedSlots:_vm._u([{key:"cell(device_uuid)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.maskUuid(item.device_uuid))+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [(!_vm.isActiveDevice(item.device_uuid))?_c('b-button',{attrs:{"variant":"light-danger"},on:{"click":function($event){return _vm.confirmDelete(item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('btn.delete'))+" ")]):_c('span',{staticClass:"d-inline-block",attrs:{"id":"mobile-delete-btn","tabindex":"0"}},[_c('b-button',{staticStyle:{"pointer-events":"none"},attrs:{"variant":"light-danger","disabled":""}},[_vm._v(" "+_vm._s(_vm.$t('btn.delete'))+" ")])],1),_c('b-tooltip',{attrs:{"target":"mobile-delete-btn"}},[_vm._v(_vm._s(_vm.$t('help.cant_delete_current_session')))])]}}])})],1)],1)],1),_c('b-modal',{attrs:{"id":"deleteDeviceModal","centered":"","hide-header":"","hide-footer":""},on:{"hidden":_vm.hideModal}},[_c('div',{staticClass:"text-center my-4"},[_vm._v(_vm._s(_vm.$t('modal.confirm_device_delete')))]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('button',{staticClass:"btn btn-hover-bg-danger btn-text-danger btn-hover-text-white border-0 font-weight-bold mr-2",attrs:{"type":"button"},on:{"click":_vm.hideModal}},[_vm._v(" "+_vm._s(_vm.$t('btn.cancel'))+" ")]),_c('button',{staticClass:"btn btn-hover-bg-success btn-text-success btn-hover-text-white border-0 font-weight-bold",attrs:{"type":"button"},on:{"click":_vm.deleteDevice}},[_vm._v(" "+_vm._s(_vm.$t('btn.confirm'))+" ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }